@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

$orange: #ea6c3a;
$Blue: #5058ed;
$Black: #1a181b;
$White: #f8f8f8;

.hero-section {
    position: relative;
    background-image: url(../media/hero-bg.jpeg);
    background-size: cover;
    height: 100vh;
    z-index: 0;

    color: white;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
                circle at center,
                rgba(0, 0, 0, 0.3) 0%,
                rgba(253, 243, 243, 0) 100%
            ),
            linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        z-index: 0;
    }
}
.wellcome-section {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 130px);
    align-items: center;
    text-align: center;
    top: 130px;
    width: 100%;
    left: 0;

    .text-welcome {
        position: relative;
        top: 0;
        margin: auto;
        padding: 0;
    }
    h1 {
        font-size: 4.4rem;
        font-weight: bolder;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px;
        margin: -10px 0;
    }

    p {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        margin: 2rem 0;
        padding: 0px;
        font-weight: 300;
    }

    button {
        padding: 1.1rem 2.7rem;
        background-color: #ea6c3a;
        color: white;
        border: none;
        border-radius: 50px;
        font-size: 20px;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 1px;
    }
}
.grid1.home {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
    margin: 5.6rem 6.6rem;
    align-items: center;
    height: 50vh;
    position: relative;

    .grid1-item-text.home {
        padding-top: 0;
        padding-bottom: 0;

        h1 {
            font-family: 'poppins', sans-serif;
            font-size: 30px;
            font-weight: 800;
            letter-spacing: 1px;
        }
        .h1-text {
            margin-top: 20px;
        }
        p {
            font-family: 'poppins', sans-serif;
            padding: 20px 0 0 0;
            font-weight: 500;
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
    .grid-item {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
    }
    .img-grid1 {
        border-radius: 65px;
        width: 100%;
    }
    .grid-item img {
        width: auto;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }
}
///cheack///
.grid1 {
    display: grid;
    grid-template-columns: 39% 51%;
    grid-gap: 10rem;
    margin: 6.6rem;
    align-items: center;
}

.highlight-section {
    font-size: 1.5rem;
    text-align: center;
    background-color: #5058ed;
    color: #fff;
    height: auto;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;

    .highlight-text {
        padding: 100px 0px;
        margin: 0 6.6rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            margin: 0;
            font-family: 'poppins', sans-serif;
            font-weight: 700;
            letter-spacing: 1px;
            font-size: 40px;
            line-height: 65px;
        }
    }
}

.grid2 {
    display: grid;
    grid-template-columns: 40% 46%;
    grid-gap: 14%;
    margin: 6.6rem;
    align-items: center;
    height: 50vh;
    grid-template-rows: none;
    position: relative;

    .grid2-item-text {
        h1 {
            font-family: 'poppins', sans-serif;
            font-size: 30px;
            font-weight: 800;
            letter-spacing: 1px;
        }
        p {
            padding: 20px 0 0 0;
            font-family: 'poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
    .grid-item {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .img-grid2 {
        border-radius: 65px;
        width: auto;
        height: 100%;
        position: absolute;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1300px) {
    .hero-section {
        padding: 0 1rem;
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        height: 700px !important;
    }

    .hero-section {
        padding: 0 1rem;
    }
    .wellcome-section {
        height: calc(700px - 130px) !important;
        top: 130;
        h1 {
            font-size: 3.5rem;
        }
    }

    .grid1.home {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 40%;
        margin: 4rem 3rem;
        gap: 10%;
        height: 500px;

        .grid1-item-text {
            padding: 0 20px 0 0;
        }
    }
    .grid2 {
        font-size: 9px;
        display: grid;
        grid-template-columns: 40% 50%;
        margin: 4rem 3rem;
        gap: 10%;
        height: 500px;

        .grid2-item-text {
            padding: 0 0 0 30px;
        }
    }
    .highlight-section .highlight-text h1 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .hero-section {
        height: 400px;
        background-position: center;
    }
    .wellcome-section {
        height: calc(400px - 50px);
        top: 50px;
        h1 {
            font-size: 1.7rem;
            font-weight: bolder;
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0px;
            margin: -10px 0;
        }

        p {
            font-size: 13px;
            font-weight: 300;
            color: #fff;
            margin: 1rem 0;
            padding: 0px;
            letter-spacing: 0px;
        }

        button {
            padding: 0.7rem 0.8rem;
            background-color: #ea6c3a;
            color: white;
            border: none;
            border-radius: 5rem;
            font-size: 0.6rem;
            cursor: pointer;
        }
    }
    .grid1.home,
    .grid2 {
        font-size: 7px;
        display: grid;
        grid-template-columns: 100%;
        margin: 2rem;
        gap: 20px;
    }
    .grid1.home {
        font-size: 7px;
        display: grid;
        grid-template-columns: 100%;
        margin: 2rem;
        gap: 0;
        height: auto;
        .grid1-item-text.home {
            padding: 0 20px 0 0;
            h1 {
                font-family: 'poppins', sans-serif;
                font-size: 18px;
                font-weight: 800;
            }
            p {
                font-family: 'poppins', sans-serif;
                padding: 20px 0 0 0;
                font-weight: 500;
                font-size: 13px;
            }
        }
    }
    .grid1.home .grid-item {
        position: relative;
        top: 0;
        left: auto;
        height: 250px;
        right: 0;
    }
    .img-grid1 {
        border-radius: 65px;
        width: 70%;
        height: 100%;
        left: auto;
    }

    .grid2 {
        font-size: 7px;
        display: grid;
        grid-template-columns: 100%;
        margin: 2rem;
        gap: 20px;
        grid-template-rows: none;
        height: auto;
        .grid2-item-text {
            p {
                font-family: 'poppins', sans-serif;
                padding: 20px 0 0 0;
                font-weight: 500;
                font-size: 13px;
            }
            h1 {
                font-family: 'poppins', sans-serif;
                font-size: 18px;
                font-weight: 800;
                letter-spacing: 1px;
            }
        }
        .grid-item {
            height: 250px;
        }
        .img-grid2 {
            border-radius: 65px;
            width: auto;
            margin: auto;
            height: 100%;
            left: 1%;
            right: 1%;
        }
    }

    .highlight-section {
        font-size: 0.5rem;
        height: 200px;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;

        .highlight-text {
            padding: 45px 0px;
            margin: 0 2rem;
            height: 200px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1 {
                margin: 0;
                font-family: 'poppins', sans-serif;
                font-weight: 700;
                letter-spacing: 1px;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}
@media only screen and (min-width: 400px) and (max-width: 600px) {
    .wellcome-section {
        height: calc(400px - 50px);
        top: 50px;
        h1 {
            font-size: 30px;
            font-weight: bolder;
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0px;
            margin: -10px 0;
        }

        p {
            font-size: 13px;
            font-weight: 300;
            color: #fff;
            margin: 1rem 0;
            padding: 0px;
            letter-spacing: 0px;
        }

        button {
            padding: 0.7rem 0.8rem;
            background-color: #ea6c3a;
            color: white;
            border: none;
            border-radius: 50px;
            font-size: 0.7rem;
            cursor: pointer;
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 850px) {
    .hero-section {
        background-position: center;
        height: 400px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        color: white;
        padding: 0;
        background-position: center;
    }
    .wellcome-section {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: calc(400px - 90px);
        top: 90px;
        h1 {
            font-size: 20px;
            font-weight: bolder;
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0px;
            margin: -10px 0;
        }

        p {
            font-size: 8px;
            font-weight: 300;
            color: #fff;
            margin: 1rem 0;
            padding: 0px;
            letter-spacing: 0px;
        }

        button {
            padding: 0.9rem 0.7rem;
            background-color: #9b8278;
            color: white;
            border: none;
            border-radius: 50px;
            font-size: 12px;
            cursor: pointer;
        }
    }
    .grid1.home,
    .grid2 {
        font-size: 7px;
        display: grid;
        grid-template-columns: 100%;
        margin: 2rem;
        gap: 20px;
    }
    .grid1.home {
        font-size: 7px;
        display: grid;
        grid-template-columns: 100%;
        margin: 2rem;
        gap: 0;
        height: auto;
        .grid1-item-text.home {
            padding: 0 20px 0 0;
            h1 {
                font-family: 'poppins', sans-serif;
                font-size: 18px;
                font-weight: 800;
            }
            p {
                font-family: 'poppins', sans-serif;
                padding: 20px 0 0 0;
                font-weight: 300;
                font-size: 13px;
            }
        }
    }
    .grid1.home .grid-item {
        position: relative;
        top: 0;
        left: auto;
        height: 250px;
        right: 0;
    }
    .img-grid1 {
        border-radius: 65px;
        width: 70%;
        height: 100%;
        left: auto;
    }

    .grid2 {
        font-size: 7px;
        display: grid;
        grid-template-columns: 100%;
        margin: 2rem;
        gap: 20px;
        grid-template-rows: none;
        height: auto;
        .grid2-item-text {
            p {
                font-family: 'poppins', sans-serif;
                padding: 20px 0 0 0;
                font-weight: 500;
                font-size: 13px;
            }
            h1 {
                font-family: 'poppins', sans-serif;
                font-size: 18px;
                font-weight: 800;
                letter-spacing: 1px;
            }
        }
        .grid-item {
            height: 250px;
        }
        .img-grid2 {
            border-radius: 65px;
            width: auto;
            margin: auto;
            height: 100%;
        }
    }

    .highlight-section {
        font-size: 0.5rem;
        height: 200px;
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;

        .highlight-text {
            padding: 45px 0px;
            margin: 0 2rem;
            height: 200px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1 {
                margin: 0;
                font-family: 'poppins', sans-serif;
                font-weight: 700;
                letter-spacing: 1px;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 850px) {
    .grid1.home {
        display: grid;
        grid-template-columns: 100%;
        margin: 4rem 4rem;
        height: auto;
    }
    .wellcome-section h1 {
        font-size: 45px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 787px) {
    .wellcome-section {
        h1 {
            font-size: 35px;
        }
        p {
            font-size: 14px;
        }
        button {
            padding: 0.9rem 1.5rem;
            background-color: #ea6c3a;
            color: white;
            border: none;
            border-radius: 50px;
            font-size: 12px;
            cursor: pointer;
        }
    }

    .grid1.home {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 2rem 3rem;
        gap: 0;

        .grid1-item-text {
            padding: 0 20px 0 0;
        }
        .grid-item.home {
            display: block;
        }
        .img-grid1 {
            border-radius: 50px;
            height: 100%;
            left: 3rem;
            width: auto;
            position: absolute;
            top: 0;
            left: auto;
            height: 100%;
            right: 0;
        }
    }
    .grid2 {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 2rem 3rem;
        gap: 0;

        .grid2-item-text {
            padding: 0 0 0 30px;
        }
        .img-grid2 {
            border-radius: 50px;
            height: 90%;
        }
    }

    .highlight-section {
        font-size: 0.5rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
    .hero-section {
        padding: 0 1rem;
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        height: 500px;
    }

    .wellcome-section {
        height: calc(500px - 80px);
        top: 80px;
        h1 {
            font-size: 2.5rem;
        }
        p {
            font-size: 1rem;
        }
        button {
            padding: 1rem 2rem;
            background-color: #ea6c3a;
            color: white;
            border: none;
            border-radius: 50px;
            font-size: 15px;
            cursor: pointer;
        }
    }

    .grid1.home {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 2rem 3rem;
        gap: 0;

        .grid1-item-text {
            padding: 0 20px 0 0;
        }
        .grid-item img {
            display: block;
            width: 70%;
            height: 250px;
            left: auto;
        }
    }
    .grid2 {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 2rem 3rem;
        gap: 0;
        grid-template-rows: none;
    }

    .highlight-section {
        font-size: 0.5rem;
        height: 200px;

        .highlight-section .highlight-text {
            padding: 52px 52px;
            margin: 0 2rem;
        }
    }
}

@media only screen and (min-width: 850px) and (max-width: 1025px) {
    .hero-section {
        padding: 0 1rem;
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        height: 500px;
    }

    .hero-section {
        padding: 0 1rem;
    }
    .wellcome-section {
        height: calc(500px - 80px);
        top: 80px;

        h1 {
            font-size: 50px;
        }
        p {
            font-size: 20px;
        }
        button {
            padding: 1rem 2rem;
            background-color: #ea6c3a;
            color: white;
            border: none;
            border-radius: 50px;
            font-size: 15px;
            cursor: pointer;
        }
    }

    .grid1.home {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 4rem 2rem;
        gap: 0;
        height: 300px;

        .grid1-item-text {
            padding: 0 20px 0 0;
        }
        .grid-item img {
            height: 90%;
        }
    }
    .grid2 {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 4rem 2rem;
        gap: 0;
        height: 300px;

        .grid2-item-text {
            padding: 0 0 0 30px;
            h1 {
                font-size: 18px;
            }
            p {
                font-size: 13px;
            }
        }
        .img-grid2 {
            height: 90%;
        }
    }
    .highlight-section {
        .highlight-text h1 {
            font-size: 40px;
        }
    }

    .img-grid1,
    .img-grid2 {
        border-radius: 50px;
        width: 100%;
    }
    .grid1.home .grid1-item-text.home h1 {
        font-family: 'poppins', sans-serif;
        font-size: 18px;
        font-weight: 800;
        letter-spacing: 1px;
    }
    .grid1.home .grid1-item-text.home p {
        font-size: 13px;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .hero-section {
        padding: 0 1rem;
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        height: 800px;
    }

    .hero-section {
        padding: 0 1rem;
    }
    .wellcome-section {
        height: calc(800px - 130px);
        top: 130px;

        h1 {
            font-size: 70px;
        }
        p {
            font-size: 20px;
        }
        button {
            padding: 1.1rem 2rem;
            background-color: #ea6c3a;
            color: white;
            border: none;
            border-radius: 50px;
            font-size: 20px;
            cursor: pointer;
        }
    }

    .grid1.home {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 4rem 6.6rem;
        gap: 0;
        height: 400px;

        .grid1-item-text {
            padding: 0 20px 0 0;
        }
        .grid-item img {
            height: 90%;
        }
    }
    .grid2 {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 4rem 6.6rem;
        gap: 0;
        height: 400px;

        .grid2-item-text {
            padding: 0 0 0 30px;
          
        }
        .img-grid2 {
            height: 90%;
        }
    }
    .highlight-section {
        .highlight-text h1 {
            font-size: 40px;
        }
    }

    .img-grid1,
    .img-grid2 {
        border-radius: 50px;
        width: 100%;
    }
}
