.slider-container1 {
    margin: 0rem 0rem 0rem 6.6rem;
}
.laps-container.mobileSection {
    display: none;
}

.slider-container1 h1 {
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 1px;
}

.gridpage3 {
    display: grid;
    grid-template-columns: 40% 49%;
    grid-gap: 11%;
    margin: 4rem 6.6rem 7rem;
    height: auto;

    .grid-item {
        height: 100%;
        top: 0;
        right: 0;
        img {
            width: 85%;
            height: auto;
        }
    }
    .page2gridtext {
        h1 {
            font-size: 30px;
            font-weight: 800;
            letter-spacing: 1px;
        }
        p {
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
}

.laps-container {
    text-align: center;
    background: #ea6c3a;
    padding: 100px 0 1rem;
    color: #fff;

    h1 {
        font-size: 30px;
        font-weight: 800;
        letter-spacing: 1px;
    }
    .grid-lap {
        display: grid;
        grid-template-columns: 50% 46%;
        grid-gap: 4%;
        margin: 10rem 6.6rem 10rem;
        .grid1-item-text {
            // padding: 6% 0;
            text-align: left;
            h1 {
                font-size: 30px;
                font-weight: 700;
            }
            p {
                // padding: 40px 0 0 0;
                font-weight: 200;
                font-size: 18px;
                letter-spacing: 1px;
            }
        }

        .grid-item {
            img {
                width: 95%;
                height: auto;
                border-radius: 65px;
            }
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 850px) {
    .grid1.gridpage3 .page2gridtext h1 {
        font-size: 18px;
        font-weight: 800;
    }
    .gridpage3 .page2gridtext p {
        font-size: 13px;
        letter-spacing: 1px;
    }
    .slider-container1 h1 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 281px) {
    .gridpage3 {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 4%;
        margin: 2rem;
        height: auto;
        grid-template-rows: auto;
        align-items: start;

        .page2gridtext {
            h1 {
                font-size: 18px;
                font-weight: 800;
            }
            p {
                font-size: 13px;
            }
        }
        .grid-item {
            img {
                display: block;
                position: absolute;
                top: 90px;
                left: 0;
                width: 100%;
            }
        }
    }

    .slider-container1 {
        margin: 0rem 0rem 0rem 2rem;
    }
    .gridpage2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .laps-container {
        .grid-lap {
            grid-template-columns: 100%;
            margin: 2rem 2rem;

            h1 {
                font-size: 18px;
            }

            .grid-lap {
                .img-grid1 {
                    display: block;
                }
                .grid1-item-text {
                    padding: 9%;
                    text-align: left;
                }
                h1 {
                    font-size: 1.8rem;
                    font-weight: 700;
                }
            }
        }
    }
}
@media only screen and (min-width: 282px) and (max-width: 600px) {
    .gridpage3 {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 4%;
        margin: 2rem;
        height: auto;
        grid-template-rows: 200px auto;
        align-items: center;

        .grid-item {
            display: block;
            height: 100%;
            left: 0;

            .imggridpage2 {
                display: block;
                width: auto;
                height: 100%;
                margin: auto;
                left: 1%;
                right: 1%;
            }
        }
        .page2gridtext {
            p {
                font-size: 13px;
            }
            h1 {
                font-size: 18px;
                font-weight: 800;
            }
        }
    }
    .slider-container1 {
        margin: 0rem 0rem 0rem 2rem;
        width: 92%;
        h1 {
            font-size: 18px;
        }
    }

    .laps-container {
        display: none;
        .grid-lap {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 4%;
            margin: 5rem 2rem;
            .grid1-item-text {
                h1 {
                    font-size: 30px;
                    font-weight: 800;
                    letter-spacing: 1px;
                }
            }
            .grid-item img {
                width: 95%;
                height: auto;
                border-radius: 65px;
                margin: auto;
            }
        }
    }

    .laps-container.mobileSection {
        display: block;
        .grid-lap {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 4%;
            margin: 5rem 2rem;
            .grid1-item-text {
                h1 {
                    font-size: 1.8rem;
                    font-weight: 700;
                }
            }
        }
        .h1-laps {
            font-size: 1.6rem;
            margin: 0 2rem;
        }
    }
}
@media only screen and (min-width: 381px) and (max-width: 599px) {
    .gridpage3 {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 1rem;
        margin: 2rem;

        .grid-item {
            img {
                position: relative;
                top: 0;
                left: 0;
                width: auto;
            }
        }
    }
    .slider-container1 {
        margin: 0 0rem 0rem 2rem;
        h1 {
            font-size: 18px;
            font-weight: 800;
        }
    }

    .swiper-container1 {
        width: 100%;
        height: 250px;
        overflow: hidden;
        margin: 2rem 0rem;
        position: relative;
    }
}

@media only screen and (min-width: 600px) and (max-width: 769px) {
    .gridpage3 {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 0;
        margin: 2rem;
        height: 55vh;

        .page2gridtext {
            h1 {
                font-size: 30px;
                font-weight: 800;
            }
        }
        .grid1-item-text {
            p {
                font-family: 'poppins', sans-serif;
                padding: 20px 0 0 0;
                font-weight: 500;
                font-size: 13px;
            }
        }
    }
    .gridpage3 .grid-item img {
        width: 100%;
    }

    .slider-container1 {
        margin: 0 0rem 0rem 2rem;
        h1 {
            font-size: 18px;
        }
    }
    .laps-container.mobileSection {
        display: block;
        .grid-lap {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 4%;
            margin: 5rem 2rem;
            .grid1-item-text {
                h1 {
                    font-size: 1.8rem;
                    font-weight: 700;
                }
            }
        }
        .h1-laps {
            font-size: 1.6rem;
            margin: 0 2rem;
        }
    }
    .laps-container {
        display: none;
        .grid-lap {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 4%;
            margin: 5rem 2rem;
            .grid1-item-text {
                h1 {
                    font-size: 30px;
                    font-weight: 800;
                    letter-spacing: 1px;
                }
            }
            .grid-item img {
                width: 70%;
                height: auto;
                border-radius: 65px;
                margin: auto;
            }
        }
    }
}

@media only screen and (min-width: 700px) and (max-width: 1000px) {
    .imggridpage2 {
        display: block;
    }
    .grid1.gridpage3 {
        display: grid;
        grid-template-columns: 40% 49%;
        grid-gap: 11%;
        margin: 4rem 2rem;
        height: auto;
        padding-bottom: 3rem;
        .grid-item {
            display: block;
            position: relative;
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: auto;
                height: 72%;

                .img-grid1 {
                    display: block;
                }
            }
            .img-grid1.page3 {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: auto;
                height: 90%;
            }
        }
    }

    .gridpage2 {
        margin-top: 3rem;
        margin-bottom: 5rem;
    }

    .laps-container.mobileSection {
        display: none;
    }
    .laps-container {
        display: block;
        padding: 100px 2rem 1px;
        .grid-lap {
            display: grid;
            grid-template-columns: 50% 46%;
            grid-gap: 4%;
            margin: 8rem 0;

            .grid1-item-text {
                padding: 9%;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1100px) {
    .gridpage3 {
        .grid-item {
            position: relative;
            top: 0px;
            left: 0;
            height: 100%;
            width: auto;
        }
    }

    .slider-container1 {
        margin: 0rem 4rem;
    }
    .laps-container .grid-lap .grid1-item-text h1 {
        font-size: 30px;
        font-weight: 700;
    }
}
