@font-face {
    font-family: 'Poppins';
    src: url(/poppins/../poppins/Poppins-Medium.eot);
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url(/poppins/Poppins-Medium.eot?#iefix) format('embedded-opentype'),
        url(/poppins/Poppins-Medium.woff2) format('woff2'),
        url(/poppins/Poppins-Medium.woff) format('woff'),
        url(/poppins/Poppins-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Poppins';
    src: url(/poppins/Poppins-Regular.eot);
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url(/poppins/Poppins-Regular.eot?#iefix) format('embedded-opentype'),
        url(/poppins/Poppins-Regular.woff2) format('woff2'),
        url(/poppins/Poppins-Regular.woff) format('woff'),
        url(/poppins/Poppins-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/poppins/Poppins-SemiBold.eot);
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url(/poppins/Poppins-SemiBold.eot?#iefix) format('embedded-opentype'),
        url(/poppins/Poppins-SemiBold.woff2) format('woff2'),
        url(/poppins/Poppins-SemiBold.woff) format('woff'),
        url(/poppins/Poppins-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/poppins/Poppins-Black.eot);
    src: local('Poppins Black'), local('Poppins-Black'),
        url(/poppins/Poppins-Black.eot?#iefix) format('embedded-opentype'),
        url(/poppins/Poppins-Black.woff2) format('woff2'),
        url(/poppins/Poppins-Black.woff) format('woff'),
        url(/poppins/Poppins-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}



@font-face {
    font-family: 'Poppins';
    src: url(/poppins/Poppins-Bold.eot);
    src: local('Poppins Bold'), local('Poppins-Bold'),
        url(/poppins/Poppins-Bold.eot?#iefix) format('embedded-opentype'),
        url(/poppins/Poppins-Bold.woff2) format('woff2'),
        url(/poppins/Poppins-Bold.woff) format('woff'),
        url(/poppins/Poppins-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}



@font-face {
    font-family: 'Poppins';
    src: url(/poppins/Poppins-Italic.eot);
    src: local('Poppins Italic'), local('Poppins-Italic'),
        url(/poppins/Poppins-Italic.eot?#iefix) format('embedded-opentype'),
        url(/poppins/Poppins-Italic.woff2) format('woff2'),
        url(/poppins/Poppins-Italic.woff) format('woff'),
        url(/poppins/Poppins-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'Poppins';
    src: url(/poppins/Poppins-ExtraBold.eot);
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
        url(/poppins/Poppins-ExtraBold.eot?#iefix) format('embedded-opentype'),
        url(/poppins/Poppins-ExtraBold.woff2) format('woff2'),
        url(/poppins/Poppins-ExtraBold.woff) format('woff'),
        url(/poppins/Poppins-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

