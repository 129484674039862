@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

$orange: #ea6c3a;
$Blue: #5058ed;
$Black: #1a181b;
$White: #f8f8f8;

.homePage.noborder {
    display: grid;
    grid-template-columns: 23% 61%;
    top: 0px;
    position: absolute;
    align-items: center;
    background: transparent;
    height: 100px;
    padding: 0 6.6rem;
    color: $White;
    gap: 16%;
    z-index: 100;
    border: none;
    width: 100%;

    .login.mobile {
        margin-left: auto;
        padding: 0.6rem 0;
        background-color: #5058ed;
        color: white;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        cursor: pointer;
        width: 85px;
        text-align: center;
    }
}

.navbar__logo {
    width: 100%;
    height: 70px;
    background-image: url(./media/logo-fff.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.navbar__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 20px;
    justify-items: end;
    list-style: none;
}
.navbar__links li {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.navbar__links li:last-child {
    margin-right: -1rem;
}

.navbar__links .link a {
    text-decoration: none;
    color: $White;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 400;
}

.navbar__links button {
    margin-left: auto;
    padding: 0.7rem 1rem;
    background-color: $Blue;
    color: $White;
    border: none;
    border-radius: 50px;
    font-size: 20px;
    cursor: pointer;
    width: 135px;
}
.navbar__links .login a {
    color: $White;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-decoration: none;
}
.homePage .nav-btn {
    padding: 0px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: $White;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

@media only screen and (max-width: 767px) {
    .homePage {
        .logo-link {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .navbar__logo {
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }
}
@media only screen and (max-width: 600px) {
    .homePage {
        .logo-link {
            width: 100%;
            height: 100%;
            text-align: center;
        }

        .navbar__links {
            flex-direction: column;
            gap: 50px;
            align-items: center;
        }
    }

    .homePage {
        margin: 0 !important;
    }
    nav.homePage__menuItems {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: $White;
        z-index: 100;
        margin: 0;
        padding: 0;
    }
    nav.homePage__menuItems ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
    nav.homePage__menuItems li {
        margin: 1rem 0;
    }
    .homePage__Trigger.home {
        display: block;
        position: absolute;
        right: 2rem;
        font-size: 20px;
        color: $White;
    }

    .homePage__menuItems {
        display: none;
    }
    .homePage__menuItems.is-open {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: calc(100vh - 130px);
        background: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        box-sizing: border-box;
        border-top: 1px solid $orange;
    }
    .navbar__logo {
        width: 100%;
        height: 60px;
    }

    .navbar__links {
        display: none;
    }
    .homePage__menuItems.is-open .navbar__links {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;
        font-size: 20px;
        justify-items: end;
        list-style: none;
        flex-wrap: nowrap;
        padding-top: 15%;
    }
    .homePage .navbar__logo {
        height: 30px;
        position: absolute;
        width: 100%;
        padding: auto;
        margin: auto;
        top: 24%;
    }
    .navbar__links .link a {
        text-decoration: none;
        color: #1a181b;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 1px;
        font-weight: 400;
    }
    .navbar__links .login a {
        color: $White;
        font-size: 15px;
    }
    .homePage .navbar__links .login {
        margin-left: auto;
        padding: 0.4rem 0.7rem;
        background-color: #5058ed;
        color: #f8f8f8;
        border: none;
        border-radius: 50px;
        font-size: 20px;
        cursor: pointer;
        width: 120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .homePage.noborder {
        padding: 0 2rem;
    }

    .navbar__links {
        button {
            margin-left: auto;
            padding: 0.6rem 0;
            background-color: $Blue;
            color: white;
            border: none;
            border-radius: 50px;

            cursor: pointer;
            width: 128px;
        }
        .link a {
            font-size: 1.3rem;
        }
        .login a {
            color: var(--White);
            font-size: 1.2rem;
        }
    }
}

.navbar__logo {
    width: 100%;
    height: 55px;
}

.homePage-nav {
    color: #fff;

    text-align: end;
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
    .homePage .navbar__logo {
        height: 40px;
        position: absolute;
        top: 45px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
    .homePage .navbar__logo {
        height: 40px;
        position: absolute;
        top: 45px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1099px) {
    .homePage-nav {
        margin: 0 5rem;
    }
}
@media only screen and (max-width: 450px) {
    .homePage.noborder {
        display: grid;
        grid-template-columns: 100px 115px !important;
        padding-top: 0 !important;
        position: absolute;
        align-items: center;
        justify-items: stretch !important;
        background: transparent;
        min-height: auto !important;
        padding: 0 2rem !important;
        justify-content: space-between !important;
        z-index: 100;
        width: 100%;
        border: none;
        top: 0px;
        gap: 10%;

        .login.mobile {
            margin-left: auto;
            padding: 0.3rem 0;
            background-color: #5058ed;
            color: white;
            border: none;
            border-radius: 20px;
            font-size: 10px;
            cursor: pointer;
            width: 60px;
        }
    }
}

@media only screen and (min-width: 450px) and (max-width: 600px) {
    .homePage.noborder {
        display: grid;
        grid-template-columns: 100px 140px !important;
        padding-top: 0 !important;
        position: absolute;
        align-items: center;
        justify-items: stretch !important;
        background: transparent;
        min-height: auto !important;
        padding: 0 2rem !important;
        justify-content: space-between !important;
        z-index: 100;
        width: 100%;
        border: none;
        top: 0px;

        .login.mobile {
            margin-left: auto;
            padding: 0.3rem 0;
            background-color: #5058ed;
            color: white;
            border: none;
            border-radius: 20px;
            font-size: 12px;
            cursor: pointer;
            width: 75px;
        }
    }
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .homePage {
        margin: 0 !important;
    }

    nav.homePage__menuItems {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: $White;
        z-index: 100;
        margin: 0;
        padding: 0;
    }

    nav.homePage__menuItems ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    nav.homePage__menuItems li {
        margin: 1rem 0;
    }

    .homePage__Trigger.home {
        display: block;
        position: absolute;
        right: 2rem;
        font-size: 35px;
        color: $White;
    }

    .homePage__menuItems {
        display: none;
    }

    .homePage__menuItems.is-open {
        display: block;
        position: absolute;
        top: 100%;
        left: 60%;
        width: 40%;
        height: calc(100vh - 130px);
        background: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        border-top: 1px solid $orange;
        box-sizing: border-box;
        border-left: 1px solid $orange;
    }

    .navbar__logo {
        width: 100%;
        height: 60px;
    }

    .homePage.noborder {
        display: grid;
        grid-template-columns: 170px 200px !important;
        top: 0px !important;
        position: absolute;
        align-items: center;
        justify-items: stretch !important;
        background: transparent;
        height: 80px !important;
        padding: 0rem 2rem !important;
        justify-content: space-between !important;
        border: none;

        .logo-link {
            position: relative;
            width: 100%;
            height: 100%;

            .navbar__logo {
                top: 26%;
                position: absolute;
                width: 100%;
            }
        }
    }

    .navbar__links {
        display: flex;
        justify-content: flex-end;
    }

    .homePage__menuItems.is-open .navbar__links {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 20%;
        font-size: 20px;
        justify-items: end;
        list-style: none;
        gap: 10%;
    }

    .homePage .navbar__links .login a {
        color: var(--White);
        font-size: 18px;
    }

    .homePage .navbar__links button {
        margin-left: auto;
        padding: 0.7rem 1rem;
        background-color: #5058ed;
        color: white;
        border: none;
        border-radius: 50px;
        font-size: 20px;
        cursor: pointer;
        width: 130px;
    }

    .navbar__links .link a {
        text-decoration: none;
        color: #1a181b;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 400;
    }
}

@media screen and (min-width: 601px) and (max-width: 730px) {
    .navbar__links .link a {
        font-size: 16px;
    }
    .homePage .navbar__links .login a {
        color: var(--White);
        font-size: 16px;
    }
    .homePage .navbar__links .login {
        width: 100px;
    }
    .homePage__menuItems.is-open .navbar__links {
        padding: 15%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 850px){
    .homePage .login.mobile {
        margin-left: auto;
        padding: 0.6rem 0;
        background-color: #5058ed;
        color: white;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        cursor: pointer;
        width: 85px;
        text-align: center;
    }
}