.swipperSecond {
    position: relative;
    height: 100vh;
}
.swipperSecond .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 21.5vh);
    //top: var(--swiper-pagination-top, 73vh);
    left: 0;
    width: 100%;
}

.swiper-pagination-bullet {
    background: #fff;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.5);
}

.swiper-pagination-bullet-active {
    background: #5058ed;
    width: var(
        --swiper-pagination-bullet-width,
        var(--swiper-pagination-bullet-size, 11px)
    );
    height: var(
        --swiper-pagination-bullet-height,
        var(--swiper-pagination-bullet-size, 11px)
    );
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
    display: none;
}
.swipperSecond .buttun-link {
    position: absolute;
    //bottom: 6%;
    text-align: center;
    width: 100%;
    z-index: 1000;
}
.swipperSecond .buttun-link button {
    margin: auto;
    display: block;
    padding: 0.7rem 0.8rem;
    background-color: #ea6c3a;
    color: white;
    border: none;
    border-radius: 5rem;
    font-size: 0.6rem;
    cursor: pointer;
    margin-bottom: 2vh;
    font-size: 13px;
}
.swipperSecond .buttun-link a {
    font-size: 13px;
    color: #ea6c3a;
    text-decoration: none;
    margin-top: 10px;
    font-weight: 500;
}
.swipperSecond .swiper-button-prev,
.swipperSecond .swiper-button-next {
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: #fff;
}
.swipperSecond .swiper-button-prev img,
.swipperSecond .swiper-button-next img {
    width: 70%;
}

.swipperSecond {
    .swiper-container {
        overflow: hidden !important;
    }
}
.img-slide {
    position: absolute;
    z-index: 2;
    top: 15vh;
    height: 45%;
    left: 1px;
    right: 1px;
    margin: auto;

    img {
        position: relative;
        left: 1px;
        right: 1px;
        margin: auto;
        height: 90%;
    }
}

.footer-links {
    display: flex;
    gap: 5px;
    font-size: 13px;
    align-items: center;
    position: absolute;
    right: 0;

    .link {
        color: #000;
        font-size: 13px;
        text-decoration: none;
    }
    .footer-text {
        font-size: 13px;
        position: absolute;
    }
}
@media only screen and (max-width: 590px) {
    .img-slide {
        position: absolute;
        z-index: 2;
        top: 15vh;
        height: 45%;
        left: 1px;
        right: 1px;
        margin: auto;

        img {
            position: relative;
            left: 1px;
            right: 1px;
            margin: auto;
            height: 80%;
        }
    }

    .footer-links {
        .link {
            font-size: 5px;
            text-decoration: none;
        }
    }
    .footer-text {
        font-size: 5px;
    }
    .footer .footer-links {
        font-size: 5px;
    }
}
@media only screen and (max-width: 360px) {
    .footer-links {
        .link {
            font-size: 3px;
        }
    }
    .footer-text {
        font-size: 3px;
    }
}
@media only screen and (min-width: 591px) and (max-width: 767px) {
    .footer-links {
        .link {
            font-size: 6px;
            text-decoration: none;
            color: black;
        }
    }
    .footer-text {
        font-size: 6px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
    .footer-links {
        .link {
            color: #000;
            font-size: 7px;
            text-decoration: none;
        }
    }
    .footer-text {
        font-size: 7px;
    }
}

.swipperSecond-container {
    overflow: hidden;
    position: relative;
}
.slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
.swipperSecond {
    .text-firstSlide {
        z-index: 2;
        position: absolute;
        bottom: 29%;
        text-align: center;
        left: 1px;
        width: 25%;
        margin: auto;
        right: 1px;
        h1 {
            position: relative;
            left: 45%;
            transform: translateX(-50%);
            font-size: 22px;
            color: #fff;
            text-align: center;
            margin-bottom: 10px;
            font-weight: 800;
        }
    }
}
@media only screen and (max-width: 590px) {
    .swipperSecond {
        .text-firstSlide {
            z-index: 2;
            position: fixed;

            text-align: center;
            left: 1px;
            width: 12rem;
            margin: auto;
            right: 1px;
            h1 {
                position: relative;
                left: 45%;
                transform: translateX(-50%);
                font-size: 22px;
                color: #fff;
                text-align: center;
                margin-bottom: 10px;
                font-weight: 800;
            }
        }
    }
}

.slide .text,
.text-slide2 {
    z-index: 2;
    position: absolute;
    bottom: 26%;
    text-align: center;
    left: 1px;
    width: 50%;
    margin: auto;
    right: 1px;

    h1 {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px !important;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 800;
    }
}
@media only screen and (max-width: 590px) {
    .slide .text {
        z-index: 2;
        position: absolute;

        text-align: center;
        left: 1px;
        width: 15rem;
        margin: auto;
        right: 1px;
        h1 {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            font-size: 22px !important;
            color: #fff;
            text-align: center;
            margin-bottom: 0px;
            font-weight: 800;
        }
    }
    .text-slide2 {
        z-index: 2;
        position: absolute;
        bottom: 27%;
        text-align: center;
        left: 1px;
        width: 15.5rem;
        margin: auto;
        right: 1px;
        h1 {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            font-size: 22px !important;
            color: #fff;
            text-align: center;
            margin-bottom: 0px;
            font-weight: 800;
        }
    }
}
.slide .text-slide3 {
    z-index: 2;
    position: absolute;
    bottom: 30%;
    text-align: center;
    left: 1px;
    width: 50%;
    margin: auto;
    right: 1px;
    h1 {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 800;
    }
}
@media only screen and (max-width: 590px) {
    .slide .text-slide3 {
        z-index: 2;
        position: absolute;

        text-align: center;
        left: 1px;
        width: 15rem;
        margin: auto;
        right: 1px;
        h1 {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            font-size: 22px;
            color: #fff;
            text-align: center;
            margin-bottom: 0px;
            font-weight: 800;
        }
    }
}

.slide .text-matchslide {
    z-index: 2;
    position: absolute;
    bottom: 26%;
    text-align: center;
    left: 1px;
    width: 50%;
    margin: auto;
    right: 1px;
    h1 {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 800;
    }
}
@media only screen and (max-width: 590px) {
    .slide .text-matchslide {
        z-index: 2;
        position: absolute;
        bottom: 31%;
        text-align: center;
        left: 1px;
        width: 18rem;
        margin: auto;
        right: 1px;
        h1 {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            font-size: 22px;
            color: #fff;
            text-align: center;
            margin-bottom: 0px;
            font-weight: 800;
        }
    }
}

.slide-text {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    color: #fff;
    text-align: center;

    font-weight: 500;
}
@media only screen and (max-width: 590px) {
    .slide-text {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 15px;
        color: #fff;
        text-align: center;

        font-weight: 500;
    }
}
.commonElement {
    position: relative;
    width: 100%;
    bottom: 21vh;
    z-index: 1;
    text-align: center;
    height: 12vh;
}
@media only screen and (max-width: 500px) {
    .commonElement {
        position: absolute;
        width: 100%;
        bottom: 10%;
        z-index: 1;
        text-align: center;
    }
    .swipperSecond .buttun-link {
        position: absolute;
        bottom: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .swiper-button-next {
        right: var(--swiper-navigation-sides-offset, 2rem);
        left: auto;
    }
    .swiper-button-prev {
        left: var(--swiper-navigation-sides-offset, 2rem);
        right: auto;
    }
}
@media only screen and (min-width: 1025px) {
    .swipperSecond {
        h1 {
            font-size: 30px;
        }
        .slide-text {
            font-size: 18px;
        }
        .buttun-link {
            button {
                font-size: 18px;
            }
            a {
                font-size: 18px;
            }
        }
    }
    .swipperSecond .swiper-button-prev,
    .swipperSecond .swiper-button-next {
        border: 1px solid #fff;
        width: 60px;
        height: 60px;
        border-radius: 50px;
    }
    .swipperSecond .swiper-button-prev img,
    .swipperSecond .swiper-button-next img {
        width: 70%;
    }
    .swiper-button-next {
        right: var(--swiper-navigation-sides-offset, 6.6rem);
        left: auto;
    }
    .swiper-button-prev {
        left: var(--swiper-navigation-sides-offset, 6.6rem);
        right: auto;
    }
    .swipperSecond {
        .img-slide {
            top: 15%;
        }
    }

    .commonElement {
        position: absolute;
        width: 100%;
        bottom: 3%;
        z-index: 1;
        text-align: center;
    }
    .swipperSecond .buttun-link {
        position: absolute;
        bottom: 0;
    }
}
@media only screen and (min-width: 591px) and (max-width: 1024px) {
    .slide .text-matchslide {
        z-index: 2;
        position: absolute;
        bottom: 26%;
        text-align: center;
        left: 1px;
        width: 50%;
        margin: auto;
        right: 1px;
    }
    .slide .text,
    .text-slide2 {
        z-index: 2;
        position: absolute;
        bottom: 26%;
        text-align: center;
        left: 1px;
        width: 50%;
        margin: auto;
        right: 1px;
    }
    .swipperSecond .text-firstSlide {
        z-index: 2;
        position: absolute;
        bottom: 26%;
        text-align: center;
        left: 1px;
        width: 26%;
        margin: auto;
        right: 1px;
    }
    .slide .text-slide3 {
        bottom: 30%;
    }
}
