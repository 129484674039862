.team-section {
    padding: 45px 0;
    background-color: #ea6c3a;
    text-align: center;
}

.team-card-container {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 32% 32% 32%;
    margin: 5rem 6.6rem;
    gap: 2%;
    height: auto;
}

.team-card {
    background-color: #fff;
    padding: 10% 16.5%;
    border-radius: 65px;
    text-align: center;
}

.team-photo {
    width: 45%;
    height: auto;
    border-radius: 50%;
    margin-inline: auto;
}

.team-name {
    margin: 20px 0;
    font-size: 18px;
    color: #5058ed;
}

.team-description {
    font-size: 18px;
    color: #000;
    letter-spacing: 1px;
    font-weight: 500;
}
.team-section h2 {
    color: #fff;
    font-family: 'poppins', sans-serif;
    font-weight: 800;
    font-size: 30px;
    letter-spacing: 1px;
}

@media only screen and (max-width: 479px) {
    .team-card-container {
        margin: 2rem 0 0;
        height: auto;
        grid-template-columns: auto;
    }
    .team-name {
        margin: 10px 0;
        font-size: 13px;
    }

    .team-description {
        font-size: 13px;
        letter-spacing: 0;
    }
    .team-card {
        border-radius: 25px;
        padding: 10% 8%;
    }
    .team-photo {
        width: 45%;
    }

    .team-name {
        margin: 5px 0;
        font-size: 13px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .team-card-container {
        margin: 2rem 0;
        height: 200px;
    }
    .team-card-container {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 32% 32% 32%;
        margin: 5rem 0rem 3rem;
        grid-gap: 2%;
        gap: 2%;
        height: auto;
    }
    .team-name {
        margin: 10px 0;
        font-size: 13px;
    }

    .team-description {
        font-size: 13px;
        letter-spacing: 0;
    }
    .team-card {
        border-radius: 30px;
        padding: 10% 8%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
    .team-card-container {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 32% 32% 32%;
        margin: 5rem 3rem;
        gap: 2%;
        height: auto;
    }
    .team-description {
        font-size: 13px;
    }
}
@media only screen and (max-width: 500px) {
    .team-section {
        padding: 45px 2rem 6rem;
        background-color: #ea6c3a;
        text-align: center;
    }
    .team-section h2 {
        color: #fff;
        font-family: 'poppins', sans-serif;
        font-weight: 800;
        font-size: 18px;
    }
}
