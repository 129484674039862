$orange: #ea6c3a;
$Blue: #5058ed;
$Black: #1a181b;
$White: #f8f8f8;

.last-section {
    border-top: 1px solid $orange;
    position: relative;
    top: 0;
    background-image: url(./media/footer-p1.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #000;
    padding: 6rem;
    mix-blend-mode: multiply;
    align-items: center;
    width: 100%;
    height: 90vh;
    background-position: center;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 0;
    }
    .connect-box {
        position: relative;
        margin: auto;
        width: 59%;
        padding: 55px 85px;
        background-color: $White;
        text-align: center;
        border-radius: 50px;
        .break-word {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #000;
        }
        h1 {
            font-size: 35px;
            color: $Blue;
            font-weight: 800;
        }
        button {
            padding: 1rem 2rem;
            background-color: #ea6c3a;
            color: white;
            border: none;
            border-radius: 50px;
            font-size: 18px;
            cursor: pointer;
            font-weight: 600;
            letter-spacing: 1px;
            margin-top: 25px;
            margin-right: 15px;
        }
    }
}
.footer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 6.6rem;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    justify-content: space-between;
    padding: 20px 0;
    position: relative;
    .footer-links {
        display: flex;
        gap: 5px;
        font-size: 13px;
        align-items: center;
        position: absolute;
        right: 0;

        .link {
            color: #000;
            font-size: 13px;
            text-decoration: none;
        }
    }
    .footer-text {
        font-size: 13px;
        position: absolute;
    }
}

@media only screen and (max-width: 590px) {
    .last-section {
        height: 250px;
        justify-content: center;
        background-position: center;
        padding: 5%;
        .connect-box {
            position: relative;
            margin: auto;
            width: 70%;
            padding: 10%;
            background-color: rgb(255 255 255 / 78%);
            text-align: center;
            border-radius: 50px;
            font-size: 10px;

            .break-word {
                font-size: 8px;
                padding: 0 10px;
            }
            h1 {
                font-size: 18px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        margin: 0 2rem;
        align-items: center;

        flex-wrap: wrap;

        position: relative;
        padding: 10px 0;

        .footer-links {
            .link {
                font-size: 5px;
                text-decoration: none;
            }
        }
        .footer-text {
            font-size: 5px;
            span {
                display: block;
            }
        }
    }

    .last-section .connect-box {
        position: relative;
        width: 300px;
        padding: 5%;
        background-color: $White;
        text-align: center;
        border-radius: 30px;
        font-size: 10px;
    }
    .last-section .connect-box button {
        padding: 0.5rem 0.7rem;
        background-color: #ea6c3a;
        color: white;
        border: none;
        border-radius: 5rem;
        font-size: 0.4rem;
        cursor: pointer;
        margin-top: 15px;
        width: auto;
    }
}
@media only screen and (max-width: 360px) {
    .last-section {
        background-position: center;
        padding: 5%;
        .connect-box {
            width: 250px;

            border-radius: 35px;
            .break-word {
                font-size: 7px;
            }
            h1 {
                font-size: 16px;
            }
        }
    }

    .footer {
        align-items: center;
        font-size: 3px;
        flex-wrap: wrap;
        gap: 5px;
        padding: 10px 0;

        .footer-links {
            .link {
                font-size: 3px;
            }
        }
        .footer-text {
            font-size: 3px;
            span {
                display: block;
            }
        }
    }
}
@media only screen and (min-width: 591px) and (max-width: 767px) {
    .last-section {
        background-position: center;
        height: 300px;
        justify-content: center;
        .connect-box {
            position: relative;
            margin: auto;
            width: 75%;
            padding: 5%;
            background-color: $White;
            text-align: center;
            border-radius: 30px;
            font-size: 10px;
            height: auto;
            button {
                padding: 0.7rem;
                font-size: 8px;
                margin-top: 15px;
                margin-right: auto;
            }
            .break-word {
                font-size: 8px;
                padding: 0 10px;
            }
            h1 {
                font-size: 18px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        margin: 0 2rem;
        align-items: center;
        font-size: 6px;
        flex-wrap: wrap;
        padding: 10px 0;
        .footer-links {
            .link {
                font-size: 6px;
                text-decoration: none;
                color: black;
            }
        }
        .footer-text {
            font-size: 6px;
        }
    }
}

@media only screen and (max-width: 360px) {
    .connect-box {
        width: 220px;
        .break-word {
            font-size: 8px;
        }
        h1 {
            font-size: 15px;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
    .last-section {
        height: auto;
        background-position: center;
        height: 400px;
        padding: 5%;

        .connect-box {
            position: relative;

            width: 650px;
            padding: 5%;
            background-color: $White;
            text-align: center;
            border-radius: 50px;
            font-size: 10px;

            h1 {
                font-size: 30px;
            }
            button {
                font-size: 13px;
                padding: 1rem 2rem;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        margin: 0 2rem;
        align-items: center;
        font-size: 7px;
        flex-wrap: wrap;
        padding: 10px 0;
        .footer-links {
            .link {
                color: #000;
                font-size: 7px;
                text-decoration: none;
            }
        }
        .footer-text {
            font-size: 7px;
        }
    }

    .last-section .connect-box .break-word {
        padding: 0 1rem;
        font-size: 13px;
    }
}
@media only screen and (min-width: 1100px) and (max-width: 1250px) {
    .last-section {
        background-position: center;
        height: 500px;
        .connect-box {
            width: 90%;
        }
    }
}
