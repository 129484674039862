.swiper-container1 .swiper-slide:nth-child(-n+4) {
    border: none;
    background-color: transparent;
  }
  .swiper-container1  .slider-container {
    margin: 0 6.6rem 6.6rem;
}
.swiper-container1 .swiper-slide:nth-last-child(-n+2) {
    border: 3px solid #5058ed52;
    color: #5058ed52 ;
  }
  .swiper-container1 .swiper-slide:nth-child(even),
  .swiper-container1 .swiper-slide:nth-child(odd){
    background-color: transparent;
  }
  .swiper-container1 {
    width: 100%;
    height: 450px;
    overflow: hidden;
    margin: 5rem 0rem 0rem;
    position: relative;
  }
  .swiper-container1   .slide-container h1{
    font-size: 2.3rem;
   }
   .swiper-container1 .swiper-wrapper{
    height: 325px;
  }
  .swiper-container1 .swiper-slide {
  
    justify-content: center;
    align-items: center;
    border: 3px solid #5058ed;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    font-size: 2rem;
    color: #5058ed;
    position: relative;
    border-radius: 50px;
    font-weight: bolder;
  }
  
  
  .swiper-container1 .swiper-slide:nth-child(-n+4) {
    background-color: #fff;
    border: none;
  }
  
  
  .swiper-container1 .swiper-button-next,
  .swiper-container1  .swiper-button-prev {
    color: #333;
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin: 0 10px;
  }
  
  .swiper-container1 .swiper-button-next:hover,
  .swiper-container1 .swiper-button-prev:hover {
    background-color: #333;
    color: #fff;
  }
  
  .swiper-container1  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #333;
    margin: 0 5px;
    opacity: 1;
  }
  
  .swiper-container1  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  
  .swiper-container1 .hi1 {
    padding:0px 20px 20px;
    border: none;
    background: #fff;
  }
  .swiper-container1 .slide-container {
    position: relative;
  }
  .swiper-container1  .swiper-wrapper{
    height: 350px;
   
  }
  
  .line1{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .line2{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .line3{
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .swiper-container1 .swiper-slide span {
    display: block;
  }
  .swiper-container1  .hi-down1{
    padding-top: 140px;
 }
   .hi-down1.hidownCards1{
    padding-top:190px;
    padding-left:35px;
     margin: 0 0 0 20px;
  }
  .swiper-container1 .swiper-slide {
    height: 325px;
  }
 
@media only screen and (max-width:500px){
  .swiper-container1  .slider-container {
    margin: 0 2rem 2rem;
    width: 75%;
}
.swiper-container1 {
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin: 2rem 0rem 0;
  position: relative;
}
.swiper-container1 .swiper-wrapper {
  height: 250px;
}
.swiper-container1 .slider-container h1{
  font-size: 1rem;
}
 .hi-down1{
  padding-top: 95px;
 }

.hi1.firstslide , .hi-down1.secondslide{
  margin-right:8px;
}
.hi1.thirdslide {
  margin-right: 7px
}
 .hi-down1 , .hi-up1{
  margin-right:20px
 }
  .hi-down1.fourthslide{
    margin-right:-100px
  }
  .hi-down1.hidownCards1 {
    padding-top: 120px;
    padding-left: 35px;
}}
 @media only screen and (min-width:500px) and (max-width:1024px){
  .swiper-container1 {
    margin: 0 0 2rem 2rem;
    }
    .swiper-container1  .swiper-wrapper{
      height: 350px;
      padding-top:10%
    }
 }