:root {
    --swiper-tinder-no-color: red;
    --swiper-tinder-yes-color: green;
    --swiper-tinder-label-text-color: #8773fc;
    --swiper-tinder-label-text-color2: #ea6c3a;
    --swiper-tinder-label-font-size: 32px;
    --swiper-tinder-button-size: 56px;
    --swiper-tinder-button-icon-size: 32px;
}

.swiper-container {
    position: relative;
    width: 23rem;
    height: 32rem;
    z-index: 1;
    // display: flex;
    transition-property: transform;
    transition-timing-function: var(
        --swiper-wrapper-transition-timing-function,
        initial
    );
    box-sizing: content-box;
}

.swiper {
    overflow: visible !important;
}

.swiper-tinder {
    box-sizing: border-box;
    height: 58vh;

    .swiper-slide {
        overflow: hidden;
        img {
            // width: 100%;
            // height: 100%;
            object-fit: cover;
        }
    }
}
.swiper-tinder-label {
    position: absolute;
    font-size: var(--swiper-tinder-label-font-size);
    font-weight: bold;
    padding: 4px 12px;
    text-transform: uppercase;
    border-radius: 4px;
    opacity: 0;
    color: var(--swiper-tinder-label-text-color);
}
.swiper-tinder-label-yes {
    left: 3%;
    top: 5%;
    transform: rotate(-20deg);
    background-color: transparent;
    transform-origin: right top;
    color: #ea6c3a;
}

.swiper-tinder-label-no {
    right: 3%;
    top: 5%;
    transform: rotate(20deg);
    background-color: transparent;
    transform-origin: left top;
    color: var(--swiper-tinder-label-text-color);
}
.swiper-tinder-button {
    // position: absolute;
    cursor: pointer;
    bottom: 32px;
    // width: var(--swiper-tinder-button-size);
    height: var(--swiper-tinder-button-size);
    // border: 1px solid;
    border-radius: 999px;
    background: transparent;
    appearance: none;
    outline: none;
    box-shadow: none;
    z-index: 999;
    transition-duration: 100ms;
    font-size: var(--swiper-tinder-button-icon-size) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    svg {
        // width: var(--swiper-tinder-button-icon-size);
        height: var(--swiper-tinder-button-icon-size);
    }
    &-hidden {
        opacity: 0;
        pointer-events: none;
    }
    &-no {
        // border-color: var(--swiper-tinder-no-color);
        left: calc(33% - 28px);
        svg {
            // fill: var(--swiper-tinder-no-color);
        }
        &.swiper-tinder-button-active,
        &:active {
            // background: var(--swiper-tinder-no-color);
            svg {
                fill: #fff;
            }
        }
    }
    &-yes {
        // border-color: var(--swiper-tinder-yes-color);
        left: calc(66% - 28px);
        // svg {
        //     fill: var(--swiper-tinder-yes-color);
        // }
        &.swiper-tinder-button-active,
        &:active {
            // background: var(--swiper-tinder-yes-color);
            svg {
                fill: #fff;
            }
        }
    }
}
