$orange: #ea6c3a;
$Blue: #5058ed;
$Black: #1a181b;
$White: #f8f8f8;

body {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $White;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #d2cfd3e8;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
    background: #e5e5e5;
}
.first-section {
    background: #ea6c3a;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0;
    padding: 2rem 5.6rem;
    text-align: center;
    align-items: start;

    .imgs {
        padding: 6% 5%;
        display: flex;
        align-items: center;

        .firstimg {
            width: 65%;
            border-radius: 70px;
        }
        .secondimg {
            width: 50%;
            z-index: 1;
            position: relative;
            right: 40%;
            bottom: -160px;
            border-radius: 70px;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1),
                -3px 3px 3px rgba(0, 0, 0, 0.1), 3px -3px 3px rgba(0, 0, 0, 0.1),
                -3px -3px 3px rgba(0, 0, 0, 0.1);
        }
    }
    .text {
        padding: 6% 0 6% 6%;
        font-size: 50px;
        text-align: left;
        color: #fff;

        .h1-text {
            display: block;
            font-size: 4.4rem;
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
        }
    }
}

.grid-lf-sc {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.grid1 {
    display: grid;
    grid-template-columns: 32% 47%;
    grid-gap: 13rem;
    margin: 6.6rem;
    align-items: center;

    .grid1-item-text {
        .header-section {
            font-size: 30px;
            color: $orange;
            font-weight: 800;
            letter-spacing: 1px;
        }
        .sub-header {
            font-size: 22px;
            font-weight: 800;
            letter-spacing: 1px;
            padding-top: 30px;
        }
        .description {
            padding: 0;
            font-weight: 500;
            color: #000;
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
}
.grid1.page2 {
    display: grid;
    grid-template-columns: 32% 47%;
    grid-gap: 21%;
    margin: 6.6rem;
    justify-items: center;
    align-items: center;

    .grid-item {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 550px;

        .img-grid1 {
            width: 80%;
            position: absolute;
            top: 130px;
            right: 0;
            height: auto;
            border-radius: 65px;
        }
    }
}

.grid1.page2.marginpage2 {
    margin: 6.6rem 6.6rem 0;
    grid-template-columns: 39% 47%;
    gap: 14%;
    align-items: center;

    .grid-item {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 550px;

        .img-grid2 {
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            height: auto;
            border-radius: 65px;
        }
    }
    .paddingpage2 {
        padding-top: 0;
        padding-bottom: 15%;

        .numberd-text {
            display: grid;
            grid-template-columns: 10% auto;
            align-items: start;
            justify-content: center;
            justify-items: center;
            padding-top: 20px;

            span {
                font-size: 18px;
            }
            .heighlight-sen {
                text-decoration: none;
                color: #ea6c3a;
            }
        }
        .header-section {
            font-size: 30px;
            color: #ea6c3a;
            font-weight: 700;
            letter-spacing: 1px;
        }
        p {
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 1px;
            color: #000;
        }
    }
}
@media only screen and (min-width: 381px) and (max-width: 600px) {
    .first-section {
        .text {
            font-size: 0.9rem;
        }
    }
    .first-section .text .h1-text {
        font-size: 1.7rem;
    }
}
@media only screen and (max-width: 321px) {
    .first-section {
        .imgs {
            .firstimg {
                width: 100px;
            }
            .secondimg {
                width: 70px;
            }
        }
        .text {
            font-size: 0.7rem;
        }
    }
    .first-section .text .h1-text {
        font-size: 1.7rem;
    }
}
@media only screen and (max-width: 768px) {
    .grid1.page2.marginpage2 .grid-item .img-grid2 {
        width: auto;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-radius: 35px;
        margin: auto;
    }
}

@media only screen and (min-width: 491px) and (max-width: 768px) {
    .grid1 {
        font-size: 7px;
        display: grid;
        grid-template-columns: 100%;
        margin: 2rem;
        gap: 2%;

        .grid1-item-text {
            padding: 0 20px 0 0;
        }
    }

    .grid1.page2 {
        .grid-item {
            height: 0px;

            .img-grid1 {
                border-radius: 50px;
                width: 100%;
            }
        }
    }

    .first-section {
        padding: 2rem;

        .imgs {
            padding: 3% 5%;
            display: flex;

            .secondimg {
                width: 45%;
                z-index: 1;
                position: relative;
                right: 100px;
                bottom: -5.5rem;
                border-radius: 30px;
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1),
                    -3px 3px 3px rgba(0, 0, 0, 0.1),
                    3px -3px 3px rgba(0, 0, 0, 0.1),
                    -3px -3px 3px rgba(0, 0, 0, 0.1);
            }
            .firstimg {
                width: 80%;
                border-radius: 36px;
            }
        }
        .text {
            padding: 7% 0 7% 7%;
            font-size: 1.6rem;
        }
    }

    .gird1 {
        grid-template-columns: 100%;

        .grid-item {
            position: relative;
            top: 0px;
            left: 0;

            img {
                display: none;
            }
        }
        .grid1-item-text {
            .header-section {
                font-size: 20px;
            }
            .sub-header {
                font-size: 18px;
            }
        }
    }
    .team-section {
        padding: 2rem;
    }
}
@media only screen and (min-width: 600px) and (max-width: 850px) {
    .grid1 .grid1-item-text {
        .sub-header {
            font-size: 18px;
        }
        .description {
            font-size: 13px;
        }
    }
    .first-section .text .h1-text {
        font-size: 45px;
    }
    .grid1.page2.marginpage2 .paddingpage2 {
        p {
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 1px;
            color: #000;
        }
        .numberd-text {
            span {
                font-size: 13px;
            }
        }
    }
}
@media only screen and (min-width: 491px) and (max-width: 600px) {
    .first-section {
        .imgs {
            .secondimg {
                width: 50%;
                z-index: 1;
                position: relative;
                right: 36%;
                bottom: -4.5rem;
                border-radius: 30px;
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1),
                    -3px 3px 3px rgba(0, 0, 0, 0.1),
                    3px -3px 3px rgba(0, 0, 0, 0.1),
                    -3px -3px 3px rgba(0, 0, 0, 0.1);
            }
        }
    }
}
@media only screen and (min-width: 508px) and (max-width: 768px) {
    .grid1.page2.marginpage2 {
        margin: 3rem 2rem 0;
        grid-template-columns: 100%;
        gap: 2%;
        align-items: start;
        grid-template-rows: 275px auto;
        position: relative;
        height: auto;
        padding-bottom: 4rem;

        .grid-item {
            .img-grid2 {
                width: auto;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                margin: auto;
                border-radius: 35px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .grid1.page2 {
        grid-template-columns: 100%;
        margin: 2rem;

        .grid-item .img-grid2 {
            width: 80%;
            position: absolute;
            top: 0;
            right: 0;
            height: 300px;
        }
    }
    .grid1.page2.marginpage2 {
        margin: 3rem 2rem 0;
        grid-template-columns: 100%;
        gap: 2%;
        align-items: start;
        grid-template-rows: 31vh auto;
        position: relative;
        height: auto;
        padding-bottom: 4rem;

        .grid1-item-text1 {
            padding: 0 20px 0 0;
        }
        .img-grid1 {
            border-radius: 50px;
            width: 100%;
        }
    }

    .first-section {
        padding: 2rem;

        .imgs {
            .secondimg {
                width: 75px;
                z-index: 1;
                position: relative;
                right: 60px;
                bottom: -50px;
                border-radius: 25px;
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1),
                    -3px 3px 3px rgba(0, 0, 0, 0.1),
                    3px -3px 3px rgba(0, 0, 0, 0.1),
                    -3px -3px 3px rgba(0, 0, 0, 0.1);
            }
            .firstimg {
                width: 120px;
                border-radius: 30px;
            }

            .text {
                padding: 7% 0 7% 7%;
                font-size: 0.7rem;
            }
        }
    }

    .grid1 {
        grid-template-columns: 100%;

        .grid1-item-text {
            .header-section {
                font-size: 1.5rem;
            }
            .sub-header {
                font-size: 18px;
            }
            .description {
                font-size: 13px;
            }
        }
    }
    .grid1 .grid-item {
        display: none;
    }

    .grid1.page2.marginpage2 {
        .grid-item {
            display: block;
            height: 100%;
        }
        .paddingpage2 {
            .numberd-text {
                p {
                    padding: 0;
                    font-size: 13px;
                }
                span {
                    font-size: 13px;
                }
            }
            .header-section {
                font-size: 1.6rem;
            }
        }
    }
    .grid1 {
        .grid-item {
            img {
                position: relative;
                top: 0;
                left: 0;
                width: 60%;
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1099px) {
    .grid1 {
        font-size: 9px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 2rem;
        gap: 0;

        .grid1-item-text {
            padding: 0 20px 0 0;
        }
    }
    .first-section {
        padding: 2rem;

        .imgs {
            .firstimg {
                width: 75%;
                border-radius: 60px;
            }
            .secondimg {
                width: 45%;
                z-index: 10000;
                position: relative;
                right: 30%;
                bottom: -100px;
                border-radius: 30px;
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1),
                    -3px 3px 3px rgba(0, 0, 0, 0.1),
                    3px -3px 3px rgba(0, 0, 0, 0.1),
                    -3px -3px 3px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .grid1 {
        margin: 3rem 2rem;
        grid-template-columns: 40% 50%;
        gap: 10%;
    }
    .grid1.page2 {
        display: grid;
        grid-template-columns: 40% 50%;
        grid-gap: 10%;
        margin: 3rem 2rem;
        align-items: center;

        .grid-item {
            position: relative;
            top: 0px;
            left: 0;
            height: 450px;
            .img-grid1 {
                width: 80%;
                position: absolute;
                top: 0;
                right: 0px;
                height: auto;
            }
        }
    }
    .grid1.page2.marginpage2 {
        display: grid;
        grid-template-columns: 40% 50%;
        grid-gap: 10%;
        margin: 3rem 2rem;
        align-items: center;
    }
}

@media only screen and (min-width: 769px) and (max-width: 894px) {
    .first-section {
        .text {
            font-size: 25px;
        }
    }
}
@media only screen and (min-width: 894px) and (max-width: 1199px) {
    .first-section {
        .text {
            font-size: 30px;
        }
    }
}
@media only screen and (min-width: 1800px) {
    .grid1.page2.marginpage2 {
        .grid-item {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .img-grid2 {
                width: auto;
                position: absolute;
                top: 100px;
                right: 0;
                height: 60%;
                border-radius: 65px;
            }
        }
    }
    .grid1.page2 {
        .grid-item {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .img-grid1 {
                width: auto;
                position: absolute;
                top: 130px;
                right: 0;
                height: 50%;
                border-radius: 65px;
            }
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 779px) {
    .first-section {
        .imgs {
            .secondimg {
                width: 45%;
                z-index: 1;
                position: relative;
                right: 36%;
                bottom: -4.5rem;
                border-radius: 30px;
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1),
                    -3px 3px 3px rgba(0, 0, 0, 0.1),
                    3px -3px 3px rgba(0, 0, 0, 0.1),
                    -3px -3px 3px rgba(0, 0, 0, 0.1);
            }
            .firstimg {
                width: 75%;
                border-radius: 36px;
            }
        }
    }
}
@media only screen and (min-width: 648px) and (max-width: 767px) {
    .first-section {
        .text {
            padding: 7% 0 7% 7%;
            font-size: 20px;
        }
    }
}
@media only screen and (max-width: 380px) {
    .grid1.page2.marginpage2 {
        margin: 3rem 2rem 0;
        grid-template-columns: 100%;
        gap: 2%;
        align-items: start;
        grid-template-rows: 37vh auto;
        position: relative;
        height: auto;
        padding-bottom: 4rem;

        .grid-item {
            .img-grid2 {
                width: auto;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                border-radius: 35px;
                margin: auto;
            }
        }

        .grid1-item-text1 {
            .header-section {
                font-size: 1.6rem;
            }
            .numberd-text {
                span {
                    font-size: 10px;
                }
                p {
                    font-size: 10px;
                }
            }
        }
    }

    .first-section {
        .imgs {
            .firstimg {
                width: 80%;
                border-radius: 25px;
            }
        }
        .text {
            font-size: 0.8rem;
        }
    }

    .grid1.page2 {
        .grid1-item-text {
            .header-section {
                font-size: 1.6rem;
            }
            .sub-header {
                font-size: 13px;
                padding-top: 18px;
            }
            p {
                font-size: 10px;
            }
        }
    }

    .grid1 .grid-item img {
        border-radius: 40px;
    }
    @media only screen and (min-width: 381px) and (max-width: 600px) {
        .first-section {
            .text {
                font-size: 0.9rem;
            }
        }
        .first-section .text .h1-text {
            font-size: 1.7rem;
        }
    }

    .first-section .text .h1-text {
        font-size: 1.7rem;
    }
}
