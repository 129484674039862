.gridpage4 {
    .grid-item {
        img {
            width: 100%;
        }
    }
}

.slider-container {
    margin: 0 0rem 6.6rem 6.6rem;

    h1 {
        font-size: 35px;
        font-weight: 800;
        letter-spacing: 1px;
    }
}

.page3gridtext p span {
    font-weight: bolder;
}
@media only screen and (min-width: 601px) and (max-width: 850px) {
    .slider-container1 h1 {
        font-size: 18px;
    }
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .gridpage4 {
        margin: 4rem 2rem 7rem;
    }

    .slider-container {
        margin: 0 0rem 6.6rem 4rem;
    }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
    .slider-container {
        h1 {
            font-size: 18px;
            font-weight: 800;
        }
    }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
    .grid1.gridpage4 {
        display: grid;
        grid-template-columns: 37% 57%;
        grid-gap: 6%;
        margin: 2rem;
        align-items: center;
        height: auto;

        .grid-item {
            display: block;
        }
    }

    .slider-container {
        margin: 0 0rem 6.6rem 2rem;
        h1 {
            font-size: 18px;
            font-weight: 700;
        }
    }
}
@media only screen and (max-width: 599px) {
    .gridpage4 {
        margin: 2rem;
        height: auto;
    }
    .slider-container {
        margin: 0 0rem 6.6rem 2rem;
        h1 {
            font-size: 1rem;
            font-weight: 700;
        }
    }
    .laps-container.mobileSection {
        display: block;
        .grid-lap {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 4%;
            margin: 5rem 2rem;
            .grid1-item-text {
                h1 {
                    font-size: 1.8rem;
                    font-weight: 700;
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .imggridpage2 {
        display: block;
    }
    .grid1.gridpage3 {
        display: grid;
        grid-template-columns: 40% 49%;
        grid-gap: 11%;
        margin: 4rem 2rem;
        height: auto;
        padding-bottom: 3rem;
        .grid-item {
            display: block;
            position: relative;
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: auto;
                height: 100%;

                .img-grid1 {
                    display: block;
                }
            }
        }
    }
}

